import React from 'react';
import FormFieldErrorPropsTypes from './types';

export default function FormFieldError({ message, className = '' }: FormFieldErrorPropsTypes) {
  if (message === undefined || message === null) return null;

  return (
    <div className={`formField__error ${className}`}>{message}</div>
  )
}
