// extracted by mini-css-extract-plugin
export var cartContainer = "checkout-module--cartContainer--gNH9n";
export var cartItemsContainer = "checkout-module--cartItemsContainer--wvQ-B";
export var contentContainer = "checkout-module--contentContainer--w-Nh7";
export var continueShopping = "checkout-module--continueShopping--O9hpl";
export var headerContainer = "checkout-module--headerContainer--JsV7A";
export var loginContainer = "checkout-module--loginContainer--tIExr";
export var marginBottom = "checkout-module--marginBottom--aGaaJ";
export var select = "checkout-module--select--Pq92j";
export var select__label = "checkout-module--select__label--9sKOZ";
export var shopLink = "checkout-module--shopLink--SKEVX";
export var shoppingContainer = "checkout-module--shoppingContainer--RjtFR";
export var summaryContainer = "checkout-module--summaryContainer--UMYXS";