import { graphql, Link, navigate, Script } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';

import FormInputField from '../../../components/FormInputField';
import Brand from '../../../components/Brand';
import Seo from '../../../components/seo';
import Container from '../../../components/Container';
import Footer from '../../../components/Footer';
import Icon from '../../../components/Icons/Icon';
import OrderSummary from '../../../components/OrderSummary';
import { useCart } from '../../../helpers/hooks/use-cart';
import useFreshShipping from '../../../helpers/hooks/use-shipping';
import CartStorage from '../../../helpers/storage';
import { customEvent, initiateCheckout, purchase } from '../../../helpers/analytics/facebook-pixel';
import { scrollIntoView } from "../../../helpers/ui";
import Button from '../../../components/Button';
import httpRequest from '../../../helpers/http';
import { objectifyFormData } from '../../../helpers/form';
import { validateBillingDetails } from '../../../helpers/validator/checkout';
import FormFieldError from '../../../components/form-field-error';
import Alert from '../../../components/alert';

import * as styles from './checkout.module.css';
import { extractStoreKeyFromShippingUrl } from '../../../helpers/data/tools';

const CheckoutPage = ({ data }) => {
  const alertRef = useRef(null);
  const fbEventId = useRef(`event.id.ic-${Math.random() * 1000000}-${Date.now()}`).current;
  const [state, dispatch] = useCart();
  const [shippingMode, setShippingMode] = useState("door");
  const {
    websiteDetails,
    storeShipping,
  } = data;
  const store_shipping = websiteDetails.store_shipping;
  const facebookPixelId = websiteDetails.store_primary_settings.store_analytics.facebook_pixel;
  const logo = getImage(websiteDetails.localFile);
  const [shippingState] = useFreshShipping(store_shipping, storeShipping.edges);
  const storeKey = extractStoreKeyFromShippingUrl(websiteDetails.store_shipping)
  let productContent = [];
  let numItems = 0;
  const productIds = state.data?.lineItems.reduce((acc, item) => {
    numItems += Number(item?.quantity);
    productContent.push({
      id: item?.product_id,
      quantity: item?.quantity
    });
    return [...acc, item?.product_id]
  }, []);
  const getLineItemsTotal = () => {
    const totalPrice = state.data?.lineItems?.reduce((acc, { product_id, quantity }) => {
      const { regular_price } = state.data.products[product_id];
      return acc + (regular_price * quantity);
    }, 0);

    return totalPrice;
  }
  const getShippingTotal = () => {
    if (state.data.shippingLines.length > 0) {
      return parseFloat(state.data.shippingLines?.[0]?.total) || 0;
    } else {
      return 0;
    }
  }
  const updateSelectedShippingByMode = async (mode, selectedShipping) => {
    const cart = new CartStorage();
    const total = mode === "stop-desk"
      ? (selectedShipping?.node["stop-desk-cost"] || selectedShipping?.node.cost)
      : selectedShipping?.node.cost;

    const shippingLine = {
      method_id: "flat_rate",
      id: selectedShipping?.node?.zzenz_id,
      method_title: selectedShipping?.node.name,
      total: String(total)
    };
    dispatch({
      type: "SET_FIELD",
      payload: {
        key: "shippingLines",
        value: [shippingLine]
      }
    });
    await cart.updateShippingLine(shippingLine);
  }
  const onEditShipping = async (event) => {
    const { value } = event.currentTarget;
    const selectedShippind = shippingState.data.find(({ node: { location } }) => location.code === value);

    if (selectedShippind !== undefined) {
      await updateSelectedShippingByMode(shippingMode, selectedShippind);
    }
  }
  const getShippingLocation = () => {
    let selectedShippind = storeShipping.edges.find(({ node: { zzenz_id } }) => {
      return zzenz_id === state.data.shippingLines?.[0]?.id;
    });

    return selectedShippind?.node?.location?.code || "";
  }
  const onSubmit = async (event) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });
    !!facebookPixelId && customEvent("StartPurchase", {
      timestamp: Date.now()
    });

    const { currentTarget: form } = event;
    const { action, method } = form;
    const formData = new FormData(form);
    const { billing } = objectifyFormData(formData);
    const errors = validateBillingDetails(billing);

    if (errors !== false) {
      dispatch({
        type: "ERROR",
        payload: errors
      });
      !!facebookPixelId && customEvent("ValidationErrorInPurchase", {
        contents: errors,
        billing,
        timestamp: `${Date.now()}`
      });
      const errorElement = document.querySelector('.js-invalid-feedback');
      scrollIntoView(errorElement, -70);
    } else {
      const stateLocation = storeShipping.edges.find(({ node }) => {
        return node.location.code === (billing)?.state;
      });
      if ((billing)?.state) {
        (billing).state = stateLocation?.node.location.name;
      }
      if (!!state?.data?.shippingLines?.[0]) {
        delete state.data.shippingLines?.[0].id;
      }

      const cart = new CartStorage();
      const order = {
        line_items: state.data.lineItems,
        billing,
        // shipping: [],
        shipping_lines: state.data.shippingLines,
      };

      try {
        /**
         * Verify if the given product is already in the cart
         * then add that quantity to the new one and check its availability
         */
        for (let index = 0; index < order.line_items.length; index++) {
          const product = order.line_items[index];
          const productDetails = await cart.getItemDetailsById(product.product_id);
          const response = await httpRequest({
            url: `${websiteDetails.product_stock_url}/${product.product_id}/availability?quantity=${product.quantity}`,
            requestConfig: {
              method: "GET",
            }
          });

          if (response.code !== "success") {
            dispatch({
              type: "ERROR",
              payload: {
                global: `نفد هذا المنتج "${productDetails?.name}" من المخزن`
              } || response.errors
            });
            return;
          }
        }

        const response = await httpRequest({
          url: action,
          requestConfig: {
            method,
            body: JSON.stringify(order),
            headers: {
              "Content-Type": "application/json"
            }
          }
        });
        let fbpOptions;

        if (response?.code === "created") {
          dispatch({
            type: "SUCCESS",
            payload: response.message
          });
          cart.clearshoppingCart();

          // send standard events 2 meta pixel
          if (!!facebookPixelId) {
            let { first_name: fn, last_name: ln, phone: ph, email: em, state: st } = billing;
            purchase(
              getLineItemsTotal(),
              productIds,
              productContent,
              "DZD",
              "product",
              {
                pixelId: facebookPixelId,
                sourceUrl: window.location.href,
                storeKey,
                eventID: `event.id.p-${response.data.id}`
              },
              {
                fn,
                ln,
                em,
                ph,
                st,
              }
            );
            customEvent("EndOfPurchase", {
              timestamp: `${Date.now()}`
            });
            fbpOptions = {
              fn,
              ln,
              ph,
              st,
              country: "dz"
            };
            fbpOptions = !!em ? { ...fbpOptions, em } : fbpOptions;
          }
          await navigate(`/orderConfirm/`, {
            state: {
              fbp: fbpOptions,
              numItems
            },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: response.errors
          });
          if (typeof window !== "undefined" && !response.errors?.global) {
            const errorElement = document.querySelector('.js-invalid-feedback');
            scrollIntoView(errorElement, -70);
          }
          !!facebookPixelId && customEvent("ServerErrorInPurchase", {
            contents: response.errors
          });
        }
      } catch (error) {
        dispatch({
          type: "ERROR",
          payload: {
            global: "Une erreur s'est produite. Veuillez réessayer!"
          }
        });
        if (typeof window !== "undefined") {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 150
          });
        }
        !!facebookPixelId && customEvent("BrowserErrorInPurchase", {
          contents: error
        });
      }
    }
  }
  const onCloseAlert = () => {
    alertRef.current.classList.add("fade-down");
    const timerId = setTimeout(() => {
      dispatch({
        type: "SUCCESS_WITH_ERRORS",
        payload: {
          errors: null,
          message: null
        }
      });
      clearTimeout(timerId);
    }, 600);
  };
  const onChangeShippingMode = async (event) => {
    const { value: mode } = event.target;
    setShippingMode(mode);

    let selectedShipping = shippingState.data.find(({ node: { zzenz_id } }) => {
      return zzenz_id === state.data?.shippingLines[0]?.id
    });
    selectedShipping = mode === "stop-desk" && !selectedShipping?.node?.["stop-desk-cost"]
      ? undefined
      : selectedShipping;

    if (selectedShipping !== undefined) {
      await updateSelectedShippingByMode(mode, selectedShipping);
    } else {
      dispatch({
        type: "SET_FIELD",
        payload: {
          key: "shippingLines",
          value: []
        }
      });
    }
  }
  const subTotal = getLineItemsTotal();
  const shippingTotal = getShippingTotal();
  const total = shippingTotal + subTotal;
  const alertMessage = state.errors?.global;
  const alertVariant = 'danger';
  const shippingList = shippingState.data.filter(({ node: item }) => {
    if (shippingMode === "door") return !!item["cost"];
    return !!item["stop-desk-cost"];
  });
  /**
   * Display or not the shipping mode depend on the selected state
   * 
   * @returns shipping config mode
   */
  const getShippingConfig = () => {
    const selectedStateCode = getShippingLocation();
    
    if(!selectedStateCode) {
      return {
        withStopDesk: true,
        withHomeDelivery: true,
      }
    }

    const selectShippingDetails = shippingList.find(({ node: { location } }) => {
      return location.code === selectedStateCode;
    });

    return {
      withStopDesk: !!selectShippingDetails?.node?.["stop-desk-cost"],
      withHomeDelivery: !!selectShippingDetails?.node?.["cost"],
    }
  }
  const shippingConfig = getShippingConfig();

  useEffect(() => {
    (async () => {
      if (!!facebookPixelId && total > 0) {
        await initiateCheckout(
          {
            "num_items": numItems || 1,
            "content_ids": productIds,
            "value": getLineItemsTotal(),
            "currency": "DZD",
            "contents": productContent,
          },
          {
            pixelId: facebookPixelId,
            sourceUrl: window.location.href,
            storeKey,
            eventID: fbEventId
          }
        );
      }
    })();
  }, [total]);

  return (
    <div>
      <Seo
        title={"Commande"}
        pathname={"/cart/checkout/"}
        description={""}
        banner={""}
      />
      <div className={styles.contentContainer}>
        <Alert
          title={'Passer la commande'}
          message={alertMessage}
          variant={alertVariant}
          onClose={onCloseAlert}
          reference={alertRef}
          className='alert'
        />
        <Container size={'large'} spacing={'min'}>
          <div className={styles.headerContainer}>
            <div className={styles.shoppingContainer}>
              <Link className={styles.shopLink} to={'/shop/'}>
                <Icon symbol={'arrow'}></Icon>
                <span className={styles.continueShopping}>
                  Poursuivre vos achats
                </span>
              </Link>
            </div>
            <Brand logo={logo} logoAlt={websiteDetails.store_name} />
            <div className={styles.loginContainer}>
              <Link to={'/'}>Accueil</Link>
            </div>
          </div>
          <form
            className={styles.summaryContainer}
            method='POST'
            onSubmit={onSubmit}
            action={`${`${websiteDetails.store_checkout_url}`}`}
          >
            <h3>Commande</h3>
            <div className={styles.cartContainer}>
              <div className={styles.cartItemsContainer}>
                <FormInputField
                  labelName='Prénom'
                  id='first_name'
                  name='billing[first_name]'
                  placeholder='Votre prénom'
                  error={state.errors?.billing?.first_name}
                />
                <FormInputField
                  labelName='Nom de famille'
                  id='last_name'
                  name='billing[last_name]'
                  placeholder='Votre nom de famille'
                  error={state.errors?.billing?.last_name}
                />
                <FormInputField
                  labelName='Email'
                  id='email'
                  name='billing[email]'
                  placeholder='e.g. mohammed@example.com'
                  error={state.errors?.billing?.email}
                />
                <FormInputField
                  labelName='Numéro de téléphone'
                  id='phone'
                  name='billing[phone]'
                  placeholder='775354745'
                  error={state.errors?.billing?.phone}
                />
                <FormInputField
                  labelName='Adresse'
                  id='adresse'
                  name='billing[address_1]'
                  placeholder='Votre adresse'
                  error={state.errors?.billing?.address_1}
                />
                <div className={styles.marginBottom}>
                  <label
                    htmlFor='state'
                    className={styles.select__label}
                  >
                    Wilaya
                  </label>
                  <select
                    id='state'
                    name='billing[state]'
                    onChange={onEditShipping}
                    value={getShippingLocation()}
                    className={styles.select}
                  >
                    <option value={""} disabled>
                      Wilaya
                    </option>
                    {
                      shippingList.map(({ node: { location } }) => {
                        const label = location.name;

                        return (
                          <option
                            value={location.code}
                            key={location.code}
                          >
                            {label}
                          </option>
                        );
                      })
                    }
                  </select>
                  <FormFieldError
                    message={state.errors?.billing?.state}
                    className='js-invalid-feedback'
                  />
                </div>
              </div>
              <OrderSummary
                subTotal={subTotal}
                shippingTotal={shippingTotal}
                total={total}
                shippingList={shippingState.data}
                shippingLocation={getShippingLocation()}
                onEditShipping={onEditShipping}
                withAction={false}
                shippingMode={shippingMode}
                onChangeShippingMode={onChangeShippingMode}
                // todo: check if the selected state has a stop desk/home delivery
                {...shippingConfig}
              />
            </div>
            <Button
              level={'primary'}
              type='submit'
              className={styles.marginBottom}
              disabled={state.loading}
            >
              Passer la commande
            </Button>
          </form>
        </Container>
      </div>
      <Footer />
      {
        !!facebookPixelId && !state.loading && !state.message && !state.errors && (
          <Script strategy="idle">{`
            fbq('track', 'InitiateCheckout', {
              "contents": ${JSON.stringify(productContent)},
              "num_items": ${numItems || 1},
              "content_ids": ${JSON.stringify(productIds)},
              "content_category": "product",
              "value": ${getLineItemsTotal()},
              "currency": "DZD",
              "timestamp": "${Date.now()}"
            }, { "eventID": "${fbEventId}" });
          `}</Script>
        )
      }Í
    </div>
  );
};

export const pageQuery = graphql`
  query CheckoutPage {
    site {
      siteMetadata {
        checkout_url
      }
    }
    websiteDetails: zzStoreWebsiteDetails {
      store_checkout_url
      store_shipping
      product_stock_url
      store_primary_settings {
        store_analytics {
          facebook_pixel
        }
      }
      localFile {
        childImageSharp {
          gatsbyImageData(
            height: 50
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    storeShipping: allZzStoreShipping(sort: {fields: [name], order: ASC}) {
      edges {
        node {
          zzenz_id
          name
          cost
          location {
            code
            type
            name
          }
        }
      }
    }
  }
`

export default CheckoutPage;
